import React, { useEffect, useState, useRef } from "react";
import Venue, { VenuePlaceholder } from "../SearchResults/Venue";

const SpecialOffers = ({ type }) => {

    const [offers, setOffers] = useState([]);
    const [discounts, setDiscounts] = useState([]);
    const [loading, setLoading] = useState(false);
    const abortController = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            abortController.current?.abort();
            abortController.current = new AbortController();

            try {
                const response = await fetch('/availability/offers/' + type.toLowerCase(), {
                    signal: abortController.current.signal
                });

                const data = await response.json();

                setOffers(data.offers);
                setDiscounts(data.discounts);
            }
            catch (e) {
                if (e.name === 'AbortError') return;
                console.log(e);
            }
            finally {
                setLoading(false);
            }
        }

        fetchData();
    }, []);

    if (loading) return <div className="container">
        <div className="row gy-4">
            {[...Array(4)].map((x, i) => {
                return <div key={i} className={sizeClasses}>
                    <VenuePlaceholder full_width={true} />
                </div>;
            })}</div></div>;

    //Type is either SPECIALS, SEASONAL, LATEAVAILABILITY, EARLY. Last 3 are discounts. SPECIALS are offers.
    if (type == "SPECIALS" && !loading && !offers.length) return <p>No Offers Available Currently</p>;
    if (type != "SPECIALS" && !loading && !discounts.length) return <p>No Discounted Venues Available Currently</p>;

    const sizeClasses = "col-sm-12 col-md-6 col-lg-12";

    return (
        <div className="container">
            <div className="row gy-4">
                {type != "SPECIALS" && discounts?.map((venue, index) => {
                    return <div key={`OFFER_${index}`} className={sizeClasses}>
                        <Venue venue={venue} price={venue.price} full_width={true} />
                    </div>;
                })}
                {type == "SPECIALS" && offers?.map((venue, index) => {
                    return <div key={`SPECIAL_${index}`} className={sizeClasses}>
                        <Venue venue={venue} price={venue.price} discount={venue.discount}
                            special_offer={venue.special_offer} fixed_dates={true}
                            arrival_date={venue.arrival_date.date} length_of_stay={venue.length_of_stay} full_width={true} />
                    </div>;

                })}
            </div>
        </div>
    );
}

export default SpecialOffers;