import React, { useState, useContext } from "react";
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isBetween from 'dayjs/plugin/isBetween';
import Favourite from '../Favourite';
import { BookNow, Pluralise, Price } from 'components/Shared';
import AvailabilityCalendar from 'components/AvailabilityCalendar';
import { Icon } from '@iconify/react';
import Placeholder from 'react-bootstrap/Placeholder';
import ConfigContext from "contexts/configContext";
import SearchContext from "contexts/searchContext";
import styles from './venue.scss';

const IconWithCount = ({ icon, label, count }) => {
    return <>
        <span className="icon-with-count flex-column flex-xxl-row">
            <Icon icon={icon} height="auto" />
            <span>
                <Pluralise noun={label} number={count} />
            </span>
        </span>
    </>;
};

const Discounts = ({ discounts, arrivalDate, los }) => {
    const getDiscountAmount = (discount) => {
        if (discount.type == "percent") {
            return discount.value + "%";
        } else if (discount.type == "fixed") {
            return Price.Format(discount.value);
        } else {
            return discount.value;
        }
    };

    const getDateRange = (range) => {
        if (!range || !range.gte || !range.lte) {
            return null;
        }

        let format = 'Do MMM, YYYY';

        return `${dayjs(range.gte).format(format)} and ${dayjs(range.lte).format(format)}`;
    };

    const dateIsInRange = (date, range) => {
        if (!range || !range.gte || !range.lte) {
            return true;
        }

        return dayjs(date).isBetween(range.gte, range.lte, "day", "[]");
    };

    const isApplicable = (discount, arrivalDate, los) => {
        if (discount.min_nights && los > discount.min_nights) return false;
        if (discount.max_nights && los < discount.max_nights) return false;

        //if (dateIsInRange(new Date(), discount.bookable) == false) return false;
        if (arrivalDate) {
            if (dateIsInRange(arrivalDate, discount.between) == false) return false;
            if (dateIsInRange(dayjs(arrivalDate).add(los, 'day'), discount.between) == false) return false;
        }

        //Late availability discount if current date is in range {gt:arrivalDate-X, lt: arrivalDate), where x is the number of days for late availability
        if (discount.type == "lateavailability" && discount.discount.days_for_lateavailability && !dateIsInRange(new Date(), { gte: dayjs(arrivalDate).subtract(discount.discount.days_for_lateavailability, 'day'), lte: arrivalDate })) return false;

        //Early booking discount: is less than min days away
        if (discount.type == "early" && discount.discount.days_for_early) {
            let diff = dayjs(arrivalDate).diff(dayjs(), 'day') + 1; //Add 1 to account for the current day
            if (diff < discount.discount.days_for_early) return false;
        }

        return true;
    };

    let applicableDiscounts = discounts.filter(discount => isApplicable(discount, arrivalDate, los));

    return <>
        {applicableDiscounts.map((discount, i) => {
            return <div key={i} className={`discount`}>
                <div className="discount-body">
                    {discount.type == "seasonal" && <span>Save {getDiscountAmount(discount.discount)} for stays between {getDateRange(discount.between)}. {discount.bookable?.gte && <span className="text-muted">For bookings made between {getDateRange(discount.bookable)}</span>}</span>}
                    {discount.type == "lateavailability" && <span>Save {getDiscountAmount(discount.discount)} for last minute bookings, within {discount.discount.days_for_lateavailability} days of arrival</span>}
                    {discount.type == "early" && <span>Save {getDiscountAmount(discount.discount)} for bookings made
                        {discount.discount.days_for_early > 0 && <> at least {discount.discount.days_for_early} days prior to arrival</>}
                        {discount.bookable?.gte && <> between {getDateRange(discount.bookable)}.</>}
                        {discount.between?.gte && <span className="text-muted"> For stays between {getDateRange(discount.between)}.</span>}
                        {discount.discount.min_nights && <span className="text-muted"> Minimum of {discount.discount.min_nights} nights.</span>}
                        {discount.discount.max_nights && <span className="text-muted"> Maximum of {discount.discount.max_nights} nights.</span>}
                    </span>}
                </div>
            </div>;
        })}

        {applicableDiscounts.length > 1 && <p>Note: Best available offer applies!</p>}
    </>;
};

const VenuePlaceholder = ({ full_width }) => {
    return <div className={`venue-listing text-primary ${full_width ? 'full-width' : ''}`}>
        <Placeholder as="div" className="venue-img ratio ratio-4x3" animation="wave">
            <Placeholder xs={12} style={{ width: '100%' }} />
        </Placeholder>
        <div className="venue-listing-body">
            <Placeholder as="div" className="d-flex justify-content-between" animation="glow">
                <Placeholder as="h3" className="mb-1" xs={10} />
                <Placeholder xs={1} size="xs" />
            </Placeholder>
            <Placeholder as="p" size="sm" animation="glow">
                <Placeholder className="mt-0 fs-6" xs={6} />
            </Placeholder>
            <div className="d-flex gap-4 flex-wrap justify-content-between">
                {Array.from({ length: 3 }).map((_, idx) =>
                    <Placeholder as="div" className="icon-with-count flex-fill flex-column flex-xl-row" key={idx} animation="glow">
                        <Placeholder as={Icon} icon="tabler:square-filled" height="auto" />

                        <Placeholder className="flex-fill w-100" />
                    </Placeholder>
                )}
            </div>
            <Placeholder as="p" animation="glow">
                <Placeholder xs={12} />
                <Placeholder xs={12} />
                <Placeholder xs={12} />
                <Placeholder xs={10} />
                <Placeholder size="sm" xs={10} />
            </Placeholder>

            <Placeholder as="div" className="d-flex justify-content-between align-items-center" animation="glow">
                <Placeholder as="p" xs={3} size="sm" />
                <Placeholder.Button variant="primary" xs={4}>
                </Placeholder.Button>
            </Placeholder>
        </div>
    </div>
};

const Venue = ({ venue, price, arrival_date, length_of_stay, discount, special_offer, full_width }) => {
    dayjs.extend(advancedFormat);
    dayjs.extend(isBetween);
    const { config } = useContext(ConfigContext);
    const { searchValue } = useContext(SearchContext);

    const [showAvailability, setShowAvailability] = useState(false);

    return (
        <div className={`venue-listing ${showAvailability ? 'expanded' : ''} ${full_width ? 'full-width' : ''}`}>
            <div className={`venue-img ratio ${full_width ? 'ratio-16x9' : 'ratio-4x3'}`}>
                <img src={`${config.BASE_URL}${venue.link}/photos/main`} loading="lazy" className="img-fluid" />
            </div>

            {
                //Hidden by CSS for now, until I think of a better way to find out if there is an applicable discount outside of the Discounts component
                (special_offer || discount > 0 || venue.discounts?.length || false) && <div className={`special-offer-icon ${special_offer ? 'is-offer' : ''}`}>
                    {special_offer ? "Offer" : "Save"}
                </div>
            }
            <div className="venue-listing-body">
                <div className="d-flex">
                    <h3 className="mb-1 flex-fill">{venue.name}</h3>
                    {false && <Favourite id={venue.super_control_ref} />}
                </div>
                <p className="mt-0 text-muted text-start fs-6">{venue.property.address.town}</p>
                <p className="d-flex gap-1 flex-wrap justify-content-between justify-content-xl-around">
                    <IconWithCount icon="iconoir:group" label="Guest" count={venue.occupancy.combined} />
                    <IconWithCount icon="fluent:bed-24-regular" label="Bedroom" count={venue.property.number_of_bedrooms} />
                    <IconWithCount icon="solar:bath-linear" label="Bathroom" count={venue.property.number_of_bathrooms} />
                </p>
                <p className="flex-fill font-small">{venue.description}</p>
                {!special_offer && //Don't list discounts if there is a special offer in place
                    <Discounts discounts={venue.discounts} arrivalDate={arrival_date || searchValue?.date} los={searchValue?.los} />
                }
                {arrival_date && <div><em>
                    <strong>{dayjs(arrival_date).format('dddd ')}</strong>
                    {dayjs(arrival_date).format('Do MMM, YYYY')}</em> for {length_of_stay} night{length_of_stay > 1 && "s"}
                </div>}
                <div className="d-flex justify-content-between align-items-end">

                    <p className="m-0"><a target="_blank" href={venue.link}> More Details <Icon icon="solar:arrow-right-up-outline" height="1rem" /></a></p>
                    {price && <>
                        <div className="pricing">
                            <Price price={price} discount={discount} />
                            <BookNow venueRef={venue.super_control_ref} venueName={venue.name} arrivalDate={arrival_date || searchValue.date} los={length_of_stay || searchValue.los} capacity={searchValue?.adults} />
                        </div>
                    </>}

                    {!price && <button className="btn btn-outline-primary btn-sm" onClick={() => setShowAvailability(!showAvailability)}>
                        <Icon icon="mdi:calendar" /> {showAvailability ? 'Hide' : 'Show'} Availability
                    </button>}
                </div>

                {showAvailability && <div className="mt-2">
                    <AvailabilityCalendar venueRef={venue.super_control_ref} venueName={venue.name} numMonths="1" colSizes="" />
                </div>}
            </div>
        </div >
    );
};

export { VenuePlaceholder };
export default Venue;