import React, { createContext, useState, useEffect } from 'react';

const ConfigContext = createContext({});

export const ConfigContextProvider = ({ children }) => {
    const [config, setConfig] = useState({
        GOOGLE_MAPS_API_KEY: "",
        PHONE_NUMBER: "01629 888 666",
        CONTACT_EMAIL: "hello@peakvenues.co.uk",
        BASE_URL: "https://www.peakvenues.co.uk",
        MAP_ZOOM_LEVEL: 10,
        MAP_ZOOM_LEVEL_MIN: 13,
        MAP_CENTER: { lat: 53.18, lng: -1.78 },
        MIN_LENGTH_OF_STAY: 1,
        MAX_LENGTH_OF_STAY: 31
    });

    useEffect(() => {
        fetch('/availability/config')
            .then(response => response.json())
            .then(data => {
                setConfig(Object.assign
                    (config, data));
            });
    }, []);

    return (
        <>
            <ConfigContext.Provider value={{ config }}>
                {children}
            </ConfigContext.Provider>
        </>
    );
};

export default ConfigContext;